/**
 * Komponente ´Tag´
 */

import {
	execute,
	extend,
	getTransitionDuration,
	noop
} from '../../js/utils/index';
import {
	isElement,
	isString
} from '../../js/utils/is';

import Data           from '../../js/dom/data';
import EventHandler   from '../../js/dom/event-handler';
import SelectorEngine from '../../js/dom/selector-engine';
import Manipulator    from '../../js/dom/manipulator';

// -------
// Private
// -------

const NAME       = 'tag';
const DATA_KEY   = `ifab.${NAME}`;
const EVENT_KEY  = `.${DATA_KEY}`;
// const API_KEY   = '.data-api';
// const BASE_CLASS = `${NAME}`;

const DEFAULTS = {
	container: null,
	onDelete : noop,
	onDeleted: noop
};

// -------
// Privat
// -------

const handleRemovable = (element) => {
	if (Manipulator.hasDataAttribute(element, 'removable')) {
		const label  = Manipulator.getDataAttribute(element, 'label') || remove;
		const button = Manipulator.createElementFrom(`<button aria-label="${label}" class="icon-button" type="button">
			<span class="icon-button__icon">
				<svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
					<polyline points="6 6 18 18"/>
					<polyline points="6 18 18 6"/>
				</svg>
			</span>
		</button>`);

		Manipulator.elementAppend(button, element);
	} else {
		const button = SelectorEngine.find(`button`, element);

		for (const element of button) {
			element.remove();
		}
	}
};

/**
 * Monitor tag changes.
 *
 * @type {MutationObserver}
 */
const observer = new MutationObserver((mutations) => {
	for (const mutation of mutations) {
		if (mutation.type === 'attributes' && mutation.attributeName === 'data-removable') {
			handleRemovable(mutation.target);
		}
	}
});

/**
 * ´Tab group´-Element initialisieren.
 *
 * @param {HTMLElement} element
 * @param {Object} o
 * @returns {HTMLElement}
 */
const render = (element, o) => {
	// Wurde Element schon initialisiert?
	if (Data.get(element, `${DATA_KEY}.initialized`)) {
		return element;
	}

	EventHandler.on(element, `click${EVENT_KEY}`, 'button', (event) => {
		event.preventDefault();

		const duration = getTransitionDuration(element);

		execute(
			o.onDelete,
			element,
			event
		);

		EventHandler.trigger(element, `will-removed${EVENT_KEY}`);

		Manipulator.addClass(element, '-remove');

		setTimeout(() => {
			element.hidden = true;

			execute(
				o.onDeleted,
				element,
				event
			);

			EventHandler.trigger(element, `has-removed${EVENT_KEY}`);
		}, (duration + 50));
	});

	handleRemovable(element);

	observer.observe(element, {
		attributes: true
	});

	// Initialisierungsstatus setzen.
	Data.set(element,`${DATA_KEY}.initialized`, true);
};

// -------
// Public
// -------

/**
 * ´Tag´-Elemente zusammenstellen und initialisieren.
 *
 * @param {HTMLElement|String|null} [m=null]
 * @param {Object} [o={}]
 * @returns {HTMLElement|Array}
 */
const init = (m = null, o = {}) => {
	const _o = extend({}, DEFAULTS, o);

	let ret;

	if (isElement(m)) {
		ret = render(m, _o);
	} else {
		const collection = SelectorEngine.find(
			(isString(m)) ? m : `[data-c="${NAME}"]`,
			_o.container || document.documentElement
		);

		ret = [];

		for (const element of collection) {
			ret.push(render(element, _o));
		}
	}

	return ret;
};

// Export
export default {
	init: init
};
