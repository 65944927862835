/**
 * Components
 */

// import Data           from '../../shared/js/dom/data';
// import SelectorEngine from '../../shared/js/dom/selector-engine';

import Button         from '../../shared/components/button/button';
import Details        from '../../shared/components/details/details';
import List           from '../../shared/components/list/list';
import Player         from '../../shared/components/player/player';
import Slider         from '../../shared/components/slider/slider';
import ScrollProgress from '../../shared/components/scroll-progress/scroll-progress';
import TabGroup       from '../../shared/components/tab-group/tab-group';
import MediaGallery   from '../../shared/components/media-gallery/media-gallery';
import Form           from '../../shared/components/form/form';
import Jumplist       from '../../shared/components/jumplist/jumplist';
import Tag            from '../../shared/components/tag/tag';
import ProgressBar    from '../../shared/components/progress-bar/progress-bar';
import ProgressRing   from '../../shared/components/progress-ring/progress-ring';
import RevealPassword from '../../shared/components/reveal-password/reveal-password';

import CustomNavigation from './custom/navigation/navigation';

// ------
// Public
// ------

const components = () => {
	Button.init();
	List.init();
	Details.init();
	Slider.init();
	TabGroup.init();
	Player.init();
	MediaGallery.init();
	ScrollProgress.init()
	Jumplist.init();
	// const form = SelectorEngine.findOne('#exampleallfields-126');
	//
	// Data.set(form, 'customFormValidation', function(data) {
	// 	console.log(data);
	// });
	Form.init();
	Tag.init();
	ProgressBar.init();
	ProgressRing.init();
	RevealPassword.init();
	CustomNavigation.init();
};

// Export
export default components;
