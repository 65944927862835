/**
 * Vendors ´Cookie consent´
 */

import {
	lockBodyScrolling,
	unlockBodyScrolling
} from '../../../shared/js/utils/scroll';

import SelectorEngine from '../../../shared/js/dom/selector-engine';
import EventHandler   from '../../../shared/js/dom/event-handler';

// -------
// Private
// -------

window.dataLayer = window.dataLayer || [];
window.GtagLoaded = false;

const gtagInit = function(id, lib) {
	if(!window.GtagLoaded) {
		window['dataLayer'].push({
			config: id,
			js    : new Date()
		});

		const firstScript = document.getElementsByTagName('script')[0];
		const newScript   = document.createElement('script');

		newScript.async = true;
		newScript.src   = `${lib}${id}`;

		firstScript.parentNode.insertBefore(newScript, firstScript);

		window.GtagLoaded = true;
	}
};

// ------
// Public
// ------

const init = () => {
	let inst = null;

	if (typeof ScCookie !== 'undefined') {
		inst = new ScCookie({
			on: {
				showPreferences: function() {
					lockBodyScrolling(this);
				},
				hidePreferences: function() {
					unlockBodyScrolling(this);
				},
				saveConsent    : function(consent) {
					document.location.reload();
				},
				init           : function(consent) {
					// Youtube?
					if (this.getConsent('youtube')) {
						const collection = SelectorEngine.find('[data-player-type="youtube"]');

						for (const element of collection) {
							EventHandler.trigger(element, 'disable.privacyMode');
						}
					}

					// Vimeo?
					if (this.getConsent('vimeo')) {
						const collection = SelectorEngine.find('[data-player-type="vimeo"]');

						for (const element of collection) {
							EventHandler.trigger(element, 'disable.privacyMode');
						}
					}

					// Google Analytics?
					if (this.getConsent('googleanalytics')) {
						// Google Tag Manager einbinden
						gtagInit('UA-121790273-1', 'https://www.googletagmanager.com/gtag/js?id=');

						window.dataLayer.push({
							event : 'cookieOptInGoogleAnalytics'
						});

						// // eslint-disable-next-line unicorn/no-document-cookie
						// document.cookie                  = 'ga-disable-UA-129301-1=false; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
						// window['ga-disable-UA-129301-1'] = false;

					// } else {
					// 	// eslint-disable-next-line unicorn/no-document-cookie
					// 	document.cookie                  = 'ga-disable-UA-129301-1=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
					// 	window['ga-disable-UA-129301-1'] = true;
					//
					}
				}
			}
		});
	}

	// Video: Aktivieren und abspielen
	// EventHandler.on(document.body, 'click', '[data-privacy-enable]', (ev) => {
	// 	console.log(Manipulator.getDataAttribute(ev.delegateTarget, 'privacy-enable'));
	// });

	// Custom-Trigger für Cookie-Einstellungen anzeigen.
	EventHandler.on(document.body, 'click', '[data-privacy-settings]', (event) => {
		event.stopPropagation();

		window.ifabCookieConsent.togglePreferences();
	});

	return window.ifabCookieConsent = inst;
};

// Export
export default init;
