import {
	noop,
	extend
} from './index';

// ------
// Privat
// ------

const KEY_REGEX = /[&\/\\#, +()$~%.'":*?<>{}]/g;

const loadedScripts = new Map();

// ------
// Public
// ------

/**
 * Script aus Dom entfernen
 *
 * @param {string} s
 * @param {Function} callback
 */
const removeScript = (s, callback = noop) => {
	const key = s.replace(KEY_REGEX, '');

	let script;

	if (loadedScripts.has(s)) {
		script = loadedScripts.get(s);
	} else if(loadedScripts.has(key)) {
		script = loadedScripts.get(key);
	}

	if (script) {
		script.remove();
		callback();
	}
}

/**
 * Script im Dom integrieren
 *
 * @param {string} src
 * @param {Object} o
 */
const loadScript = (src, o = {}) => {
	if (src) {
		const _o = extend({}, {
			async   : false,
			callback: noop,
			defer   : false,
			id      : '',
			target  : document.body
		}, o);

		const key = _o.id || src.replace(KEY_REGEX, '');

		let script = (loadedScripts.has(key)) ? loadedScripts.get(key) : null;

		if (script) {
			// Script ist schon integriert. Führe Callback aus.
			script.options.callback();
		} else {
			// Script integrieren.

			script = document.createElement('script');

			if (_o.id) {
				script.id = _o.id;
			}

			if (_o.async) {
				script.async = true;
			}

			if (_o.defer) {
				script.defer = true;
			}

			script.src = src;

			// Script speichern.
			loadedScripts.set(key, {
				options: _o,
				element: script
			});

			const target = _o.target || document.body;

			target.appendChild(script);

			script.onload = () => {
				_o.callback();
			};
		}
	}
};

// Export
export {
	loadScript,
	removeScript
};
