import {extend} from '../utils/index';

// -------
// Private
// -------

window.pageInstance = window.pageInstance || {};

const DEFAULT_TRANSLATIONS = {
	'de': {
		// Eintrag
		itemNext: 'nächster Eintrag',
		itemPrev: 'vorheriger Eintrag',
		itemGoTo: 'gehe zu Eintrag {{index}}',
		// Bild
		imageNext: 'nächstes Bild',
		imagePrev: 'vorheriges Bild',
		imageZoom: 'Bild vergrößern',
		// Slider
		slideNext: 'nächster Slide',
		slidePrev: 'vorheriger Slide',
		slideGoTo: 'gehe zu Slide {{index}}',
		// Modal
		modalClose: 'Overlay schließen',
		modalOpen : 'Overlay öffnen',
		// Begrifflichkeiten
		close   : 'schließen',
		closeEsc: 'per ESC-Taste schließen',
		download: 'herunterladen',
		error   : 'Ein Fehler ist aufgetreten',
		next    : 'weiter',
		prev    : 'zurück'
	},
	'en': {
		// Eintrag
		itemNext: 'next item',
		itemPrev: 'previous item',
		itemGoTo: 'go to item {{index}}',
		// Bild
		imageNext: 'next image',
		imagePrev: 'previous image',
		imageZoom: 'zoom image',
		// Slider
		slideNext: 'next slide',
		slidePrev: 'previous slide',
		slideGoTo: 'go to slide {{index}}',
		// Modal
		modalClose: 'close overlay',
		modalOpen : 'open overlay',
		// Begrifflichkeiten
		close   : 'close',
		closeEsc: 'close with the ESC key',
		download: 'download',
		error   : 'An error has occurred',
		next    : 'next',
		prev    : 'previous'
	}
};

// ------
// Public
// ------

export default class TranslationManager {
	constructor() {
		if (window.pageInstance.translationManager) {
			return window.pageInstance.translationManager;
		} else {
			this.translations = DEFAULT_TRANSLATIONS;
			this.setLang();

			return window.pageInstance.translationManager = this;
		}
	}

	/**
	 * Aktuelle Sprache setzen.
	 * Wird keine Sprache übergeben, dann wird die Sprache des aktuellen Dokumentes verwendet.
	 *
	 * @param {string} [lang='']
	 */
	setLang(lang = '') {
		if (!lang) {
			const l = document.documentElement.getAttribute('lang') || navigator.language;

			this.lang = this.parseLangKey(l);
		} else {
			this.lang = this.parseLangKey(lang);
		}
	}

	/**
	 * Aktuelle Sprache zurückgeben.
	 *
	 * @returns {string|*}
	 */
	getLang() {
		return this.lang;
	}

	/**
	 * @param {string} key
	 * @param {data} data - {key: 'val', ...}
	 */
	addLanguage(key, data) {
		const k = this.parseLangKey(key);

		if (!this.translations[k]) {
			this.translations[k] = {};
		}

		this.translations[k] = extend({}, this.translations[k], data);
	}

	/**
	 * @param {object} data - `{lang: {key: 'val', ...}, ...}`
	 */
	addLanguages(data) {
		for (const key of Object.keys(data)) {
			this.addLanguage(key, data[key]);
		}
	}

	/**
	 * @param {string} key
	 * @param {object} [data={}]
	 * @returns {string}
	 */
	translate(key, data = {}) {
		let s = this.findTranslation(key);

		return (s) ? this.parseTranslation(s, data) : s;
	}

	/**
	 * @param {string} key
	 * @returns {string}
	 */
	findTranslation(key) {
		let t = key;

		if (this.translations && this.translations[this.lang]) {
			t = this.translations[this.lang][key] || key;
		}

		return t || key;
	}

	/**
	 * @param {Array} keys
	 * @returns {Array}
	findTranslations(keys) {
		return keys.map(key => this.findTranslation(key));
	}
	 */

	/**
	 * @param {string} s
	 * @returns {string}
	 */
	parseLangKey(s) {
		let l = s.toString();

		return l.toLowerCase().split('-')[0];
	}

	/**
	 *
	 * @param {string} s
	 * @param {Object} data - Datenobjekt `{key: 'val'}` mit im String zu ersetzenden Teilstücken
	 * @param {boolean} [removeEmptyPlaceholder=true]
	 * @returns {string}
	 */
	parseTranslation(s, data, removeEmptyPlaceholder = false) {
		let replaced = s;

		if (data) {
			for (const key of Object.keys(data)) {
				replaced = replaced.replace(new RegExp('{{' + key + '}}', 'gm'), data[key]);
			}
		}

		if (removeEmptyPlaceholder) {
			replaced = replaced.replace(new RegExp('{{(.*?)}}', 'gm'), '');
		}

		return replaced;
	}
}
