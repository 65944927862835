/**
 * Search
 */

import {
	needJquery,
	getElementFromSelector,
	executeAfterTransition,
	triggerReflow
} from '../../../shared/js/utils/index';

import SelectorEngine from '../../../shared/js/dom/selector-engine';
import Manipulator    from '../../../shared/js/dom/manipulator';
import Data           from '../../../shared/js/dom/data';
import EventHandler   from '../../../shared/js/dom/event-handler';

import Drawer from '../../../shared/components/drawer/drawer';
import {
	lockBodyScrolling,
	unlockBodyScrolling
} from "../../../shared/js/utils/scroll";

// -------
// Privat
// -------

const pageSearch       = SelectorEngine.findOne('#page-search-drawer');

// -------
// Public
// -------


/**
 * Drawer (Suche-Overlay)
 */
const setupDrawer = () => {
	const collection = SelectorEngine.find('[aria-controls="page-search-drawer"]');

	for (const trigger of collection) {
		const drawer  = new Drawer(pageSearch, {
			useOverlay : true,
			onShow     : function(el) {
				// Hier wird eine jQuery-Instanz zurückgegeben.
				const element = el[0];

				// Scrollen des `<body/>` sperren
				// lockBodyScrolling(element);

				Manipulator.addClass(trigger, '-active');
			},
			onHide: function(el) {
				// Hier wird eine jQuery-Instanz zurückgegeben.
				const element = el[0];

				// Scrolen des `<body/>` entsperren.
				// unlockBodyScrolling(element);

				Manipulator.removeClass(trigger, '-active');
			}
		});

		EventHandler.on(trigger, 'click.ifab.pageSearchDrawer', function(event) {
			const collection = SelectorEngine.find('[data-drawer].-initialized', document.body);

			for(const element of collection) {
				if(element.classList.contains('-open')) {
					Drawer(element).hide();
				}
			}

			if (this.classList.contains('-active')) {
				drawer.hide();
			} else {
				drawer.show();
			}
		});
	}
};

const search = () => {
	setupDrawer();
};

// Export
export default search;
