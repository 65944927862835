import {
	normalizeAttributeKey,
	normalizeAttributeValue
} from '../utils/index';
import {isString} from '../utils/is';

import Data from './data';

// ------
// Privat
// ------

const allowDisabledOnElements = new Set([
	'button',
	'fieldset',
	'input',
	'optgroup',
	'option',
	'select',
	'textarea'
]);

const allowRequiredOnElements = new Set([
	'input',
	'select',
	'textarea'
]);

// ------
// Public
// ------

//
// Element handling
//

/**
 * Ein Element auf Basis eines Templatestrings generieren.
 *
 * @param {string} tpl - Templatestring
 * @returns {HTMLElement}
 */
const createElementFrom = (tpl) => {
	const div = document.createElement('div');

	div.innerHTML = tpl;

	return div.children[0];
};

/**
 * Element innerhalb eines Elementes an letzter Position einfügen.
 *
 * @param {string|HTMLElement} m
 * @param {HTMLElement} [target=document.body] target
 * @returns {HTMLElement}
 */
const elementAppend = (m, target = document.body) => {
	let element = m;

	if (isString(element)) {
		element = createElementFrom(element);
	}

	target.append(element);

	return element;
};

/**
 * Element innerhalb eines Elementes an erster Position einfügen.
 *
 * @param {string|HTMLElement} m
 * @param {HTMLElement} [target=document.body] target
 * @returns {HTMLElement}
 */
const elementPrepend = (m, target = document.body) => {
	let element = m;

	if (isString(element)) {
		element = createElementFrom(element);
	}

	target.insertBefore(element, target.firstChild);

	return element;
};

/**
 * Element vor einem Elemente einfügen.
 *
 * @param {string|HTMLElement} m
 * @param {HTMLElement} [target=document.body] target
 * @returns {HTMLElement}
 */
const elementBefore = (m, target = document.body) => {
	let element = m;

	if (isString(element)) {
		element = createElementFrom(element);
	}

	target.parentNode.insertBefore(element, target.nextSibling);

	return element;
};

//
// Element class handling
//

/**
 * CSS-Klasse(n) einem Element hinzufügen.
 *
 * @param {(HTMLElement|Element|EventTarget)} element
 * @param {(string|string[])} cl
 */
const addClass = (element, cl) => {
	const list = cl.split(' ');

	for (const item of list) {
		if (!element.classList.contains(item))
		{
			element.classList.add(item);
		}
	}
};

/**
 * CSS-Klasse(n) von einem Element entfernen.
 *
 * @param {(HTMLElement|Element|EventTarget)} element
 * @param {(string|string[])} cl
 */
const removeClass = (element, cl) => {
	const list = cl.split(' ');

	for (const item of list) {
		if (element.classList.contains(item))
		{
			element.classList.remove(item);
		}
	}
};

/**
 * CSS-Klasse eines Elementes ´wechseln´.
 *
 * @param {(HTMLElement|Element|EventTarget)} element
 * @param {string} cl
 */
const toggleClass = (element, cl) => {
	if (element.classList.contains(cl))
	{
		removeClass(element, cl);
	}
	else
	{
		addClass(element, cl);
	}
};

//
// Element attribute - Accessibility
//

/**
 * Attribut `aria-...` eines Elementes auslesen.
 *
 * @param {(HTMLElement|Element|EventTarget)} element
 * @param {string} key
 */
const getAria = (element, key) => {
	const val = element.getAttribute(`aria-${key}`);

	return (val) ? normalizeAttributeValue(val) : '';
};

/**
 * Attribut `aria-...` eines Elementes entfernen.
 *
 * @param {(HTMLElement|Element|EventTarget)} element
 * @param {string} key
 */
const removeAria = (element, key) => {
	element.removeAttribute(`aria-${key}`);
};

/**
 * Attribut `aria-...` eines Elementes setzen.
 *
 * @param {(HTMLElement|Element|EventTarget)} element
 * @param {string} key Attributname ohne `aria-`
 * @param {string|number|boolean} [val=] Attributwert
 */
const setAria = (element, key, val = '') => {
	element.setAttribute(`aria-${key}`, val.toString());
};

/**
 * Role-Attribut setzen.
 *
 * @param {(HTMLElement|Element|EventTarget)} element
 * @param {string} val
 */
const setRole = (element, val) => {
	if (val) {
		element.setAttribute('role', val);
	} else {
		element.removeAttribute('role');
	}
};

//
// Element attribute - Data
//

/**
 * Datenattribut eines Elementes auf Vorhandensein prüfen.
 *
 * @param {(HTMLElement|Element|EventTarget)} element
 * @param {string} key
 * @return {boolean}
 */
const hasDataAttribute = (element, key) => {
	return element.hasAttribute(`data-${normalizeAttributeKey(key)}`);
};

/**
 * Datenattribut eines Elementes auslesen.
 *
 * @param {(HTMLElement|Element|EventTarget)} element
 * @param {string} key
 * @return {boolean|null|number|string}
 */
const getDataAttribute = (element, key) => {
	return normalizeAttributeValue(element.getAttribute(`data-${normalizeAttributeKey(key)}`));
};

/**
 * Dataattribut eines Elementes setzen.
 *
 * @param {(HTMLElement|Element|EventTarget)} element
 * @param {string} key
 * @param {string|number} [val='']
 */
const setDataAttribute = (element, key, val = '') => {
	element.setAttribute(`data-${normalizeAttributeKey(key)}`, val);
};

/**
 * Datenattribute eines Elementes auslesen.
 *
 * @param {(HTMLElement|Element|EventTarget)} element
 * @return {Object}
 */
const getDataAttributes = (element) => {
	if (!element)
	{
		return {};
	}

	const attributes = {
		...element.dataset
	};

	for (const key of Object.keys(attributes)) {
		attributes[key] = normalizeAttributeValue(attributes[key]);
	}

	return attributes;
};

/**
 * Dataattribut eines Elementes entfernen.
 *
 * @param {(HTMLElement|Element|EventTarget)} element
 * @param {string} key
 */
const removeDataAttribute = (element, key) => {
	element.removeAttribute(`data-${normalizeAttributeKey(key)}`);
};

/**
 * Datenattribut eines Elementes switchen.
 *
 * @param {(HTMLElement|Element|EventTarget)} element
 * @param {string} key
 * @return {HTMLElement|Element|EventTarget}
 */
const toggleDataAttribute = (element, key) => {
	if (hasDataAttribute(element, key)) {
		Data.set(element, 'toggleAttributeOldValue', getDataAttribute(element, key));

		removeDataAttribute(element, key);
	} else {
		setDataAttribute(element, key, Data.get(element, 'toggleAttributeOldValue') || '');
	}

	return element;
};

//
// Element standard
//

/**
 * Element nicht fokussierbar machen.
 *
 * @param {(HTMLElement|Element|EventTarget)} element
 */
const makeFocusable = (element) => {
	element.setAttribute('tabIndex', '0');
};

/**
 * Element fokussierbar machen.
 *
 * @param {(HTMLElement|Element|EventTarget)} element
 */
const makeNotFocusable = (element) => {
	element.setAttribute('tabIndex', '-1');
};

/**
 * Attribut `disabled` eines Elementes setzen.
 *
 * @param {(HTMLElement|Element|EventTarget)} element
 * @param {boolean} [flag=true]
 * @param {string} [cl='_diabled'] - Zusätzliche, alternative CSS-Klasse.
 */
const setDisabled = (element, flag = true, cl = '_disabled') => {
	const disableAllowed = allowDisabledOnElements.has(element.tagName.toLowerCase());

	if (flag === true)
	{
		if (disableAllowed) {
			element.setAttribute('disabled', flag.toString());
		}

		addClass(element, cl);
	}
	else
	{
		if (disableAllowed) {
			element.removeAttribute('disabled');
		}

		removeClass(element, cl);
	}
};

/**
 * Attribut `required` eines Elementes setzen.
 *
 * @param {(HTMLElement|Element|EventTarget)} element
 * @param {boolean} [flag=true]
 */
const setRequired = (element, flag = true) => {
	const requiredAllowed = allowRequiredOnElements.has(element.tagName.toLowerCase());

	if (flag === true) {
		if (requiredAllowed) {
			element.setAttribute('required', '');
		}
	} else {
		element.removeAttribute('required');
	}
};

/**
 * Attribut `title` eines Elementes setzen.
 *
 * @param {(HTMLElement|Element|EventTarget)} element
 * @param {string} val
 * @param {boolean} [forceEmpty=true] - Leere Titelattribute zulassen.
 */
const setTitle = (element, val, forceEmpty = true) => {
	if (val || (!val && forceEmpty)) {
		element.setAttribute('title', val);
	} else {
		element.removeAttribute('title');
	}
};

// Export
const Manipulator = {
	createElementFrom,
	elementAppend,
	elementPrepend,
	elementBefore,

	addClass,
	removeClass,
	toggleClass,

	getAria,
	removeAria,
	setAria,
	setRole,

	getDataAttribute,
	setDataAttribute,
	getDataAttributes,
	hasDataAttribute,
	removeDataAttribute,
	toggleDataAttribute,

	makeFocusable,
	makeNotFocusable,
	setDisabled,
	setRequired,
	setTitle
};

export default Manipulator;
