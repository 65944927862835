/**
 * Komponente ´Tab group´
 */

import {
	execute,
	extend,
	getElementFromSelector,
	getOffset,
	needJquery,
	noop,
	triggerReflow
}                              from '../../js/utils/index';
import {
	isElement,
	isString
}                              from '../../js/utils/is';
import {scrollElementIntoView} from '../../js/utils/scroll';
import {focusVisible}          from '../../js/utils/focus-visible';

import SelectorEngine from '../../js/dom/selector-engine';
import Manipulator    from '../../js/dom/manipulator';
import Data           from '../../js/dom/data';

// -------
// Private
// -------

const $ = needJquery();

const NAME       = 'tab-group';
const DATA_KEY   = `ifab.${NAME}`;
// const EVENT_KEY  = `.${DATA_KEY}`;
// const API_KEY    = '.data-api';
const BASE_CLASS = `${NAME}`;

const DEFAULTS = {
	container        : null,
	onHide           : noop,
	onHidden         : noop,
	onShow           : noop,
	onShown          : noop,
	scrollToActiveTab: 'smooth',
	showIndicator    : true
};

/**
 * Rückgabe des aktiven Tab-Element.
 *
 * @param {HTMLElement} tabgroup
 * @returns {HTMLElement}
 */
const getActiveTab = (tabgroup) => {
	const tabs = Data.get(tabgroup, `tabs`);

	return tabs.find((tab) => {
		return Manipulator.getAria(tab, 'selected') === true;
	});
};

/**
 * Positioierung des Indikators zum aktiven Tab-Element.
 *
 * @param {HTMLElement} tabgroup
 * @param {HTMLElement} [tabOverwrite = null]
 */
const repositionIndicator = (tabgroup, tabOverwrite = null) => {
	const indicator = Data.get(tabgroup, 'indicator');
	const placement = Data.get(tabgroup, 'placement');
	const nav       = Data.get(tabgroup, 'nav');
	const tab       = tabOverwrite || getActiveTab(tabgroup);

	if (!tab || !indicator) {
		return;
	}

	const width  = tab.clientWidth;
	const height = tab.clientHeight;
	const offset = getOffset(tab, nav);
	const top    = offset.top + nav.scrollTop;
	const left   = offset.left + nav.scrollLeft;

	switch (placement) {
		case 'vertical':
			indicator.style.width     = `${width}px`;
			indicator.style.height    = 'auto';
			indicator.style.transform = `translateX(${left}px)`;
			break;

		case 'horizontal':
			indicator.style.width     = 'auto';
			indicator.style.height    = `${height}px`;
			indicator.style.transform = `translateY(${top}px)`;
			break;
	}
};

/**
 * Bei einigen Ausrichtungen wird der Indikator animiert während sich seine
 * Position aufgrund der Größenänderung der Kompnente ändert.
 * Der Aufruf dieser Methode verhindert das die Animation während der
 * Größenänderung ausgeführt wird ... was etwas "natürlicher" erscheint.
 *
 * @param {HTMLElement} tabgroup
 */
const preventIndicatorTransition = (tabgroup) => {
	const indicator = Data.get(tabgroup, 'indicator');

	if (indicator) {
		const val = indicator.style.transition;

		indicator.style.transition = 'none';

		requestAnimationFrame(() => {
			indicator.style.transition = val;
		});
	}
};

/**
 * Anzeige des Indikators aktualisieren.
 *
 * @param {HTMLElement} tabgroup
 */
const syncIndicator = (tabgroup) => {
	const indicator = Data.get(tabgroup, 'indicator');

	if (indicator) {
		const tab = getActiveTab(tabgroup);

		if (tab) {
			indicator.style.display = 'block';

			repositionIndicator(tabgroup);
		} else {
			indicator.style.display = 'none';
		}
	}
};

/**
 * ´Tab group´-Element initialisieren.
 *
 * @param {HTMLElement} element
 * @param {Object} o
 * @returns {HTMLElement}
 */
const render = (element, o) => {
	// Wurde Element schon initialisiert?
	if (Data.get(element, `${DATA_KEY}.initialized`)) {
		return element;
	}

	const placement = (element.classList.contains('-tabs-left') || element.classList.contains('-tabs-right')) ? 'horizontal' : 'vertical';
	const nav       = SelectorEngine.findOne(`.${BASE_CLASS}__nav`, element);
	const tabsGroup = SelectorEngine.findOne(`.${BASE_CLASS}__tabs`, element);
	const tabs      = SelectorEngine.find('.tab', element);

	Data.set(element, `options`, o);
	Data.set(element, `placement`, placement);
	Data.set(element, `nav`, nav);
	Data.set(element, `tabsGroup`, tabsGroup);
	Data.set(element, `tabs`, tabs);

	if (o.showIndicator) {
		const indicator = Manipulator.createElementFrom(`<div class="${BASE_CLASS}__indicator"/>`);

		Data.set(element, `indicator`, indicator);

		Manipulator.elementAppend(indicator, tabsGroup);
	}

	const observerResize = new ResizeObserver(() => {
		if (o.showIndicator) {
			preventIndicatorTransition(element);
			syncIndicator(element);
		}

		const activeTab = getActiveTab(element);

		if (typeof activeTab !== 'undefined') {
			scrollElementIntoView(getActiveTab(element), nav, 'horizontal', o.scrollToActiveTab);
		}
	});

	// eslint-disable-next-line unicorn/no-array-for-each
	tabs.forEach(tab => {
		// Events mit jQuery erweitern :(.
		// Sonstige Möglichkeiten erfordern komplette separate Initialisierungen.
		$(tab)
			.on('show.bs.tab', function(event) {
				const panel = getElementFromSelector(event.target);

				repositionIndicator(element, event.target);

				if (panel) {
					panel.hidden = false;
					triggerReflow(panel);

					Manipulator.setAria(panel, 'selected', true);
					Manipulator.setAria(panel, 'hidden', false);
				}

				execute(
					o.onShow,
					event.target,
					panel,
					event
				);
			})
			.on('shown.bs.tab', function(event) {
				const panel = getElementFromSelector(event.target);

				execute(
					o.onShown,
					event.target,
					panel,
					event
				);
			})
			.on('hide.bs.tab', function(event) {
				const panel = getElementFromSelector(event.currentTarget);

				if (panel) {
					panel.hidden = true;

					Manipulator.setAria(panel, 'selected', false);
					Manipulator.setAria(panel, 'hidden', true);
				}

				execute(
					o.onHide,
					event.currentTarget,
					panel,
					event
				);
			})
			.on('hidden.bs.tab', function(event) {
				const panel = getElementFromSelector(event.currentTarget);

				execute(
					o.onHidden,
					event.currentTarget,
					panel,
					event
				);
			});

		focusVisible.observe(tab);
	});

	observerResize.observe(nav);

	// Initialisierungsstatus setzen.
	Data.set(element, `${DATA_KEY}.initialized`, true);

	return element;
};

// -------
// Public
// -------

/**
 * ´Tab group´-Elemente zusammenstellen und initialisieren.
 *
 * @param {HTMLElement|String|null} [m=null]
 * @param {Object} [o={}]
 * @returns {HTMLElement|Array}
 */
const init = (m = null, o = {}) => {
	const _o = extend({}, DEFAULTS, o);

	let ret;

	if (isElement(m)) {
		ret = render(m, _o);
	} else {
		const collection = SelectorEngine.find(
			(isString(m)) ? m : `[data-c="${NAME}"]`,
			_o.container || document.documentElement
		);

		ret = [];

		for (const element of collection) {
			ret.push(render(element, _o));
		}
	}

	return ret;
};

// Export
export default {
	init : ($ ? init : noop)
};
