/**
 * Main(-JS-File)
 */

import {getRootVar} from '../shared/js/utils/index';

import components from './components/components';
import initial    from './initial/initial';
import layout     from './layout/layout';
import vendors    from './vendors/vendors';

// --------
// Let's go
// --------

document.addEventListener('DOMContentLoaded', function(event) {
	window.RootvarPrefix = getRootVar('global-rootvar-prefix');

	components();
	vendors();
	initial();
	layout();
});
