import {
	isElement,
	isNode,
	isObject
} from './is';

// -------
// Private
// -------

const MAX_UID                 = 1000000;
const MULTIPLIER_MILLISECONDS = 1000;
const TRANSITION_END          = ['transitionend', 'webkitTransitionEnd'];

const DOMContentLoadedCb = [];

/**
 * @param element
 * @returns {null|*}
 */
const getSelector = element => {
	// eslint-disable-next-line unicorn/prefer-dom-node-dataset
	let selector = element.getAttribute('data-target') || element.getAttribute('data-bs-target');

	if (!selector && element.getAttribute('aria-controls')) {
		 selector = '#' + element.getAttribute('aria-controls');
	}

	if (!selector && element.getAttribute('aria-labelledby')) {
		 selector = '#' + element.getAttribute('aria-labelledby');
	}

	if (!selector || selector === '#') {
		let href = element.getAttribute('href');

		if (!href || !href.includes('#')) {
			return null;
		}

		// Angaben von kompletten URL's beachten.
		if (href.includes('#') && !href.startsWith('#')) {
			href = `#${href.split('#')[1]}`;
		}

		selector = href && href !== '#' ? href.trim() : null;
	}

	return selector;
};

// -------
// Public
// -------

/**
 * Platzhalterfunktion.
 */
const noop = () => {};

/**
 * Aktuelles Datum.
 *
 * @returns {number}
 */
const now = () => {
	return Date.now();
};

/**
 * ´Unique ID´ mit Präfix generieren.
 *
 * @param {string} prefix
 * @returns {string}
 */
const getUid = prefix => {
	do {
		prefix += Math.floor(Math.random() * MAX_UID);
	} while (document.getElementById(prefix));

	return prefix;
};

const onDOMContentLoaded = cb => {
	if (document.readyState === 'loading') {
		// add listener on the first call when the document is in loading state
		if (!DOMContentLoadedCb.length) {
			document.addEventListener('DOMContentLoaded', () => {
				for (const cb of DOMContentLoadedCb) {
					cb();
				}
				// DOMContentLoadedCb.forEach(cb => cb());
			});
		}

		DOMContentLoadedCb.push(cb);
	} else {
		cb();
	}
};

/**
 * Prüfe auf Vorhandensein von jQuery.
 *
 * @returns {jQuery|null}
 */
const getJquery = () => {
	const {jQuery} = window;

	if (jQuery) {
		return jQuery;
	}

	return null;
};

/**
 * Prüfe auf Vorhandensein von jQuery inkl. Fehlerausgabe.
 *
 * @returns {jQuery|null}
 */
const needJquery = () => {
	const jQ                = getJquery();
	const {jQueryIsMissing} = window;

	// Fehlermeldung nur einmal ausgeben!
	if (!jQ && !jQueryIsMissing && processEnv === 'development') {
		// eslint-disable-next-line no-console
		console.error(`Unfortunately, the current environment still requires jQuery`);

		window.jQueryIsMissing = true;
	}

	return jQ;
};

/**
 *
 * @param element
 * @returns {HTMLElement|null}
 */
const getElementFromSelector = element => {
	const selector = getSelector(element);

	return selector ? document.querySelector(selector) : null;
};

/**
 * Referenz zum ´Shadow root´ eines Elementes bestimmen.
 *
 * @param {HTMLElement|Node|ParentNode} element
 * @returns {ShadowRoot|null}
 */
const getShadowRoot = element => {
	if (!document.documentElement.attachShadow) {
		return null;
	}

	if (typeof element.getRootNode === 'function') {
		const root = element.getRootNode();

		return root instanceof ShadowRoot ? root : null;
	}

	if (element instanceof ShadowRoot) {
		return element;
	}

	// when we don't find a shadow root
	if (!element.parentNode) {
		return null;
	}

	return getShadowRoot(element.parentNode);
};

/**
 * Animationsdauer eines Elementes bestimmen.
 *
 * @param {HTMLElement} element
 * @returns {number}
 */
const getTransitionDuration = element => {
	if (!element) {
		return 0;
	}

	// Lies die Animationsdauer und -verzögerung des Elementes aus.
	let {transitionDuration, transitionDelay} = window.getComputedStyle(element);

	const floatTransitionDuration = Number.parseFloat(transitionDuration);
	const floatTransitionDelay    = Number.parseFloat(transitionDelay);

	// 0 zurückgeben wenn Animationsdauer und -verzögerung nicht gefunden wurde.
	if (!floatTransitionDuration && !floatTransitionDelay) {
		return 0;
	}

	// Gibt es mehrere Angaben zu einer Animationsdauer, dann nimm die erste.
	transitionDuration = transitionDuration.split(',')[0];
	transitionDelay    = transitionDelay.split(',')[0];

	return (Number.parseFloat(transitionDuration) + Number.parseFloat(transitionDelay)) * MULTIPLIER_MILLISECONDS;
};

/**
 * Native Elementreferenz, jQuery wäre `$('#element')[0]`, zurückgeben.
 *
 * @param {HTMLElement|jQuery} element
 * @returns {HTMLElement|null}
 */
const testNativeElement = element => {
	if (isElement(element)) {
		return element.jquery ? element[0] : element;
	}

	return null;
};

/**
 * Restart a CSS Animation With JavaScript.
 *
 * @param {HTMLElement} element
 * @return void
 *
 * @see https://www.charistheo.io/blog/2021/02/restart-a-css-animation-with-javascript/#restarting-a-css-animation
 */
const triggerReflow = element => {
	// eslint-disable-next-line no-unused-expressions
	element.offsetHeight;
};

/**
 * TransitionEnd-Event eines Elementes triggern.
 *
 * @param {HTMLElement} element
 */
const triggerTransitionEnd = element => {
	for (const event of TRANSITION_END) {
		element.dispatchEvent(new Event(event));
	}
};

/**
 * Ausführung einer Funktion.
 *
 * @param {function} callback
 * @param args
 */
const execute = (callback, ...args) => {
	if (typeof callback === 'function') {
		callback(...args);
	}
};

/**
 * Funktion nach Elementanimation ausführen.
 *
 * @param {function} callback
 * @param {HTMLElement} element
 * @param {boolean} [waitForTransition=true]
 */
const executeAfterTransition = (callback, element, waitForTransition = true) => {
	if (!waitForTransition) {
		execute(callback);

		return;
	}

	let isCalled = false;

	const duration = getTransitionDuration(element) + 5;
	const handler  = ({target}) => {
		if (target !== element) {
			return;
		}

		isCalled = true;

		// EventListener entfernen.
		for (const event of TRANSITION_END) {
			element.removeEventListener(event, handler);
		}

		// Callback ausführen.
		execute(callback);
	};

	// EventListener anbinden.
	for (const event of TRANSITION_END) {
		element.addEventListener(event, handler);
	}

	// Wurde der obige EventListener nicht ausgelöst, dann starte ihn manuell.
	setTimeout(() => {
		if (!isCalled) {
			triggerTransitionEnd(element);
		}
	}, duration);
};

/**
 * Verzögerte Funktionsaufrufe.
 *
 * @param {function} func
 * @param {number} [delay=300]
 * @param {boolean} [immediate=false]
 * @returns {(function(...[*]) : void)|*}
 */
const debounce = (func, delay = 300, immediate = false) => {
	let timerId;

	return (...args) => {
		const boundFunc = func.bind(this, ...args);

		clearTimeout(timerId);

		if (immediate && !timerId) {
			boundFunc();
		}

		const calleeFunc = immediate ? () => {
			timerId = null;
		} : boundFunc;

		timerId = setTimeout(calleeFunc, delay);
	};
};

/**
 * Objekte miteinander mergen/erweitern.
 *
 * @param args
 * @returns {Object}
 */
const extend = (...args) => {
	const to       = new Object(args[0]);
	const noExtend = ['__proto__', 'constructor', 'prototype'];

	for (let i = 1; i < args.length; i += 1) {
		const nextSource = args[i];

		if (nextSource !== undefined && nextSource !== null && !isNode(nextSource)) {
			const keysArray = Object.keys(new Object(nextSource)).filter((key) => noExtend.indexOf(key) < 0);

			for (let nextIndex = 0, len = keysArray.length; nextIndex < len; nextIndex += 1) {
				const nextKey = keysArray[nextIndex];
				const desc    = Object.getOwnPropertyDescriptor(nextSource, nextKey);

				if (desc !== undefined && desc.enumerable) {
					if (isObject(to[nextKey]) && isObject(nextSource[nextKey])) {
						if (nextSource[nextKey].__swiper__) {
							to[nextKey] = nextSource[nextKey];
						} else {
							extend(to[nextKey], nextSource[nextKey]);
						}
					} else if (!isObject(to[nextKey]) && isObject(nextSource[nextKey])) {
						to[nextKey] = {};

						if (nextSource[nextKey].__swiper__) {
							to[nextKey] = nextSource[nextKey];
						} else {
							extend(to[nextKey], nextSource[nextKey]);
						}
					} else {
						to[nextKey] = nextSource[nextKey];
					}
				}
			}
		}
	}

	return to;
};

/**
 * Offest-Position eines Elementes bestimmen.
 *
 * @param {(HTMLElement|Element|EventTarget)} element
 * @param {(HTMLElement|Element|EventTarget)} parent
 * @returns {Object}
 */
const getOffset = (element, parent = null) => {
	const rectE = element.getBoundingClientRect();

	let offset;

	if (parent && (parent !== window || parent !== document.body)) {
		const rectP = parent.getBoundingClientRect();

		offset = {
			// top : Math.round(element.getBoundingClientRect().top - parent.getBoundingClientRect().top),
			// left: Math.round(element.getBoundingClientRect().left - parent.getBoundingClientRect().left)
			left: Math.round(rectE.left - rectP.left),
			top : Math.round(rectE.top - rectP.top)
		};
	} else {
		offset = {
			left: rectE.left + document.body.scrollLeft,
			top : rectE.top + document.body.scrollTop
		};
	}

	return offset;
};

/**
 * Elementposition auslesen.
 *
 * @param {(HTMLElement|Element|EventTarget)} el
 * @returns {Object}
 */
const getPosition = (el) => {
	return {
		top : el.offsetTop,
		left: el.offsetLeft
	};
};

/**
 * Root-Variablen auslesen und/oder setzen.
 *
 * @example
 * `--foo:'bar'`
 * getRootVar('foo'); // returns `bar`
 * getRootVar('fo'); // returns ''
 *
 * @param {String} key - Name der Rootvariable ohne `--`
 * @param {HTMLElement|null} [element=null] - Element
 *
 * @returns {(String|Number|Boolean)}
 */
const getRootVar = (key, element = null) => {
	const target = (isElement(element)) ? element : document.querySelector(':root');

	return getComputedStyle(target).getPropertyValue(`--${key}`);
};

/**
 * Root-Variablen auslesen und/oder setzen.
 *
 * @example
 * setRootVar('foo', 'bar'); // set `--foo:'bar'`
 *
 * @param {String} key - Name der Rootvariable ohne `--`
 * @param {String} val - Wert der Rootvariable
 * @param {HTMLElement} [element=null] - Element
 */
const setRootVar = (key, val , element = null) => {
	const target = (isElement(element)) ? element : document.querySelector(':root');

	target.style.setProperty(`--${key}`, val);
};

/**
 * @param val
 * @returns {boolean|null|number|string}
 */
const normalizeAttributeValue = (val) => {
	if (val === 'true') {
		return true;
	}

	if (val === 'false') {
		return false;
	}

	if (val === Number(val).toString()) {
		return Number(val);
	}

	if (val === '' || val === 'null') {
		return null;
	}

	return val;
};

/**
 * Key eines Data-Attributes ´normalisieren´ (erleubte Zeichen).
 *
 * @param {string} key
 * @returns {string}
 */
const normalizeAttributeKey = (key) => {
	return key.replace(/[A-Z]/g, chr => `-${chr.toLowerCase()}`);
};

// Export
export {
	debounce,
	execute,
	executeAfterTransition,
	extend,
	getElementFromSelector,
	getJquery,
	getOffset,
	getPosition,
	getSelector,
	getShadowRoot,
	getTransitionDuration,
	getUid,
	needJquery,
	noop,
	now,
	onDOMContentLoaded,
	testNativeElement,
	triggerReflow,
	triggerTransitionEnd,
	setRootVar,
	getRootVar,
	normalizeAttributeValue,
	normalizeAttributeKey
};
