import {isElement} from './is';

// -------
// Private
// -------

const TABBABLE_ELEMENT_TAGS = ['button', 'input', 'select', 'textarea', 'a', 'audio', 'video', 'summary'];

/**
 * Bestimmt unter Zuhilfename von Heuristiken, die von
 * https://github.com/focus-trap/tabbable inspiriert sind, ob das angegebene
 * Element per Tab auswählbar ist.
 *
 * @param {HTMLElement} element
 * @returns {boolean}
 */
function isTabbable(element) {
	const tag = element.tagName.toLowerCase();

	// Elements with a -1 tab index are not tabbable
	if (element.getAttribute('tabindex') === '-1') {
		return false;
	}

	// Elements with a disabled attribute are not tabbable
	if (element.hasAttribute('disabled')) {
		return false;
	}

	// Elements with aria-disabled are not tabbable
	if (
		element.hasAttribute('aria-disabled') &&
		element.getAttribute('aria-disabled') !== 'false'
	) {
		return false;
	}

	// Radios without a checked attribute are not tabbable
	if (
		tag === 'input' &&
		element.getAttribute('type') === 'radio' &&
		!element.hasAttribute('checked')
	) {
		return false;
	}

	// Elements that are hidden have no offsetParent and are not tabbable
	if (element.offsetParent === null) {
		return false;
	}

	// Elements without visibility are not tabbable
	if (window.getComputedStyle(element).visibility === 'hidden') {
		return false;
	}

	// Audio and video elements with the controls attribute are tabbable
	if (
		(tag === 'audio' || tag === 'video') &&
		element.hasAttribute('controls')
	) {

		return true;
	}

	// Elements with a tabindex other than -1 are tabbable
	if (element.hasAttribute('tabindex')) {
		return true;
	}

	// Elements with a contenteditable attribute are tabbable
	if (
		element.hasAttribute('contenteditable') &&
		element.getAttribute('contenteditable') !== 'false'
	) {
		return true;
	}

	return TABBABLE_ELEMENT_TAGS.includes(tag);
}

// -------
// Public
// -------

/**
 * Das erste und letzte Element, was per Tag auswählbar ist, zurückgeben.
 *
 * @param {HTMLElement, ShadowRoot} root
 * @returns {{start: HTMLElement, end: HTMLElement}}
 */
const getTabbableBoundary = (root) => {
	const allElements = [];

	function walk(element) {
		if (isElement(element)) {
			allElements.push(element);

			if (element.shadowRoot !== null && element.shadowRoot.mode === 'open') {
				walk(element.shadowRoot);
			}
		}

		if(element.children) {
			for (const el of element.children) {
				walk(el);
			}
		}
	}

	// Alle Elemente inkl. Root holen.
	walk(root);

	// Das erste und letzte ´tabbable´ Element finden.
	const start = allElements.find(el => isTabbable(el)) ?? null;
	const end   = allElements.reverse().find(el => isTabbable(el)) ?? null;

	return {start, end};
}

// Export
export {
	getTabbableBoundary
};
