/**
 * Komponente ´Media gallery´
 */

import {extend} from '../../js/utils/index';
import {
	isElement,
	isString
}               from '../../js/utils/is';

import SelectorEngine from '../../js/dom/selector-engine';
import Data           from '../../js/dom/data';
import Slider         from '../slider/slider';

// -------
// Private
// -------

const NAME      = 'media-gallery';
const DATA_KEY  = `ifab.${NAME}`;
// const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = `.data-api`;

const DEFAULTS = {
	grid  : {},
	slider: {}
};

const renderSlider = (element, o) => {
	Slider.single(element, o);
};

/**
 * ´Media gallery´-Element initialisieren.
 *
 * @param {HTMLElement} element
 * @param {Object} o
 * @returns {HTMLElement}
 */
const render = (element, o) => {
	// Wurde Element schon initialisiert?
	if (Data.get(element, `${DATA_KEY}.initialized`)) {
		return element;
	}

	//
	// Grid
	//

	// Only by CSS!

	//
	// Slider
	//

	const slider = SelectorEngine.findOne(`[data-c="slider-${NAME}"]`, element);

	if (slider) {
		renderSlider(slider, extend({}, {
			slidesPerView: 'auto',
			spaceBetween : 0
		}, o.slider));
	}

	// Initialisierungsstatus setzen.
	Data.set(element, `${DATA_KEY}.initialized`, true);

	return element;
};

// -------
// Public
// -------

/**
 * ´Media gallery´-Elemente zusammenstellen und initialisieren.
 *
 * @param {HTMLElement|String|null} [m=null]
 * @param {Object} [o={}]
 * @returns {HTMLElement|Array}
 */
const init = (m = null, o = {}) => {
	const _o = extend({}, DEFAULTS, o);

	let ret;

	if (isElement(m)) {
		ret = render(m, _o);
	} else {
		const collection = SelectorEngine.find(
			(isString(m)) ? m : `[data-c="${NAME}"]`,
			_o.container || document.documentElement
		);

		ret = [];

		for (const element of collection) {
			ret.push(render(element, _o));
		}
	}

	return ret;
};

// Export
export default {
	init: init
};
